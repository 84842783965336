import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AnnouncementsModule } from './announcements/announcements.module';
import { GalleryModule } from './gallery/gallery.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    AnnouncementsModule,
    GalleryModule
  ]
})
export class NewsModule { }
