import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/http/auth.service';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.css']
})
export class OthersComponent implements OnInit {

  isExpanded = false;

  constructor(public auth: AuthService) { }

  ngOnInit(): void {
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  signout(){
    sessionStorage.removeItem("token");
    window.location.href = "https://www.sijaq.org";
  }

}
