<app-collapsible-members-table #wating [data]="data()">
    <ng-template let-element let-index="index" #buttons>
        <div class="field has-addons">
            <p class="control">
                <button class="button is-small is-info" (click)="permit($event, element)">
                    <span class="icon is-small">
                        <i class="fas fa-user-check"></i>
                    </span>
                    <span>
                        Permit
                    </span>
                </button>
            </p>

            <p class="control">
                <button class="button is-small is-danger" (click)="deny($event, element.id)">
                    <span class="icon is-small">
                        <i class="fas fa-user-times"></i>
                    </span>
                    <span>
                        Deny
                    </span>
                </button>
            </p>
        </div>
    </ng-template>
</app-collapsible-members-table>

<div class="mt-3">
    <ngx-bulma-pagination [max]="max" (pageChange)="pageChange($event)"></ngx-bulma-pagination>
</div>