import { Component } from '@angular/core';
import * as dateFns from "date-fns";
import { CloudImageSourceService } from '../cloud-image-source.service';

@Component({
  selector: 'app-cloud-rgb',
  templateUrl: './cloud-rgb.component.html',
  styleUrls: ['./cloud-rgb.component.css']
})
export class CloudRgbComponent {

  currentDate = new Date();

  sources: any[];

  onDateChanged(date: Date) {
    this.currentDate = date;
    const fileName = `GK2A_AMI_RGBGCAS_${dateFns.format(this.currentDate, 'yyyyMMdd')}`;
    const dirs = ['image', dateFns.format(this.currentDate, "yyyy/MM/dd"), 'satellite', 'cloud', 'rgb'].join('/');

    this.sources = [...Array(3).keys()].map(i => dirs + '/' + fileName + "_" + (i + 1) + ".gif");
  }
}
