import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSummernoteModule } from 'ngx-summernote';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { RichTextViewerComponent } from './rich-text-viewer/rich-text-viewer.component';
import { CustomPipeModule } from '../pipe/custom-pipe.module';
import { EnhancedRichTextViewerComponent } from './rich-text-viewer/enhanced-rich-text-viewer/enhanced-rich-text-viewer.component';
import { EnhancedRichTextEditorComponent } from './rich-text-editor/enhanced-rich-text-editor/enhanced-rich-text-editor.component';
import { CheckRemovePostModalComponent } from './rich-text-viewer/enhanced-rich-text-viewer/check-remove-post-modal/check-remove-post-modal.component';
import { ModalModule } from '../modal/modal.module';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { SelectModule } from '../select/select.module';

@NgModule({
  declarations: [RichTextEditorComponent, RichTextViewerComponent, EnhancedRichTextViewerComponent, EnhancedRichTextEditorComponent, CheckRemovePostModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSummernoteModule,
    NgxGalleryModule,
    CustomPipeModule,
    ModalModule,
    SelectModule
  ],
  exports: [RichTextEditorComponent, RichTextViewerComponent, CheckRemovePostModalComponent]
})
export class RichTextModule { }
