import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sijaq-report',
  templateUrl: './sijaq-report.component.html'
})
export class SijaqReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
