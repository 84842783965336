import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titlecase2'
})
export class Titlecase2Pipe implements PipeTransform {
  transform(value: string): string {
    return value?.split(/[\s_]/gi).map(s => {
      if (!s[0])
        return s;

      if (["and", "or", "of", "for", "in", "by", "at", "on"].some(m => m == s.toLowerCase())) {
        return s.toLowerCase();
      }

      if (["crp", "baqone", "crp/baqone"].some(m => m == s.toLowerCase())) {
        return s.toUpperCase();
      }
      
      return s[0]? s[0].toUpperCase() + s.slice(1) : s
    }).join(" ");
  }
}
