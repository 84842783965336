import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/http/auth.service';
import { PostService } from 'src/app/http/post.service';
import { Post } from 'src/app/store/schema';
import { StoreService } from 'src/app/store/store.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  isShowModal = false;

  articleCountPerPage = 12;
  data: any[];
  max = 0;
  current = 1;

  private storeKey;
  subscription: Subscription;

  galleryImages: NgxGalleryImage[] = [];
  galleryContent: string;

  constructor(private post: PostService, private store: StoreService, private route: ActivatedRoute, private router: Router, public auth: AuthService) { }

  ngOnInit() {
    this.storeKey = this.route.snapshot.data.storeKey ?? this.router.url.split("/")[2];
    this.requestData();
  }

  hasImages(gallery: Post) {
    return gallery.files?.length > 0;
  }

  filesToImageSources(gallery: Post) {
    return this.hasImages(gallery) ? gallery.files.map(f => f.url) : ["https://via.placeholder.com/300x400?text=No+image"];
  }

  /* filesToImageUrl(gallery: Post) {
    const files = gallery.files;
    return files? files[Math.floor(Math.random() * files.length)].url : "https://via.placeholder.com/300x400?text=No+image";
  } */
  
  imageCount(gallery: Post) {
    return this.hasImages(gallery) ? gallery.files.length : 0;
  }

  open(gallery: Post) {
    this.router.navigate(["view", gallery.id], { relativeTo: this.route });
  }

  edit(gallery: Post) {
    // [routerLink]="[ 'edit', gallery.id ]"
    // FIXME - resolve guard
    this.post.read(this.storeKey, gallery.id, false).subscribe((post: Post) => {
      this.store.publish(this.storeKey, post)
      this.router.navigate(['edit', gallery.id], { relativeTo: this.route });
    }, err => {
      console.log(err);
      alert(err);
    });
  }

  pageChange(pageInfo) {
    this.current = pageInfo.current;
    window.scrollTo(0, 0);
    this.requestData();
  }

  requestData() {
    this.subscription?.unsubscribe();
    this.subscription = this.post.readAll(this.storeKey, this.current).subscribe(({ content, totalPages }) => {
      this.data = content;
      this.max = totalPages;
    });
  }
}
