import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-collapsible-members-table',
  templateUrl: './collapsible-members-table.component.html',
  styleUrls: ['./collapsible-members-table.component.css']
})
export class CollapsibleMembersTableComponent {

  @Input()
  data: any;

  @ContentChild('buttons', { static: false })
  buttonsTemplateRef: TemplateRef<any>;

  headers = ["Name", "Charge", "Organization", "E-Mail", "Address", ""];
  detailHeaders = ["email", "name", "nationality", "charge", "organization", "telephone", "address", "usertype", "role"];

  constructor() { }

}
