import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxGalleryImage, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { GallerySetting } from 'src/app/common/gallery-setting';
import { PostService } from 'src/app/http/post.service';
import { File, Post } from 'src/app/store/schema';
import { StoreService } from 'src/app/store/store.service';

@Component({
  selector: 'app-gallery-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css']
})
export class ViewerComponent implements OnInit {

  galleryImages: NgxGalleryImage[] = [];
  galleryOptions: NgxGalleryOptions[] = GallerySetting.options;

  data: any;

  private storeKey: string;
  private id: string;

  constructor(private post: PostService, private store: StoreService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.storeKey = "gallery";
    this.id = this.router.url.split("/").pop();

    this.post.read(this.storeKey, this.id).subscribe(data => {
      this.data = data;
      this.galleryImages = this.filesToImageSources(data).map(src => ({ medium: src }));

      this.store.publish(this.storeKey, data);
    });
  }

  filesToImageSources(gallery: Post) {
    return gallery.files.map(f => f.url);
  }

  downloadFile(file: File) {
    this.post.downloadFile(this.storeKey, this.id, file);
  }

  edit(id) {
    this.router.navigate(["news_and_events", "gallery", "edit", id]);
  }

  getFiles(){
    if(this.data != null){
      if(this.data.files.length > 0) return this.data.files;
    }
  }

  get parentUrl(): string[] {
    return this.route.snapshot.pathFromRoot
      .filter(snapshot => snapshot.url.length > 0)
      .map(snapshot => snapshot.url[0].path).slice(0, 2);
  }

  requestDeletePost(id: string) {
    this.post.delete(this.storeKey, id).subscribe(_ => {
      this.router.navigate(this.parentUrl);
    }, err => {
      console.log(err);
      alert(err);
    });
  }
}
