import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TableModule } from 'src/app/common/table/table.module';
import { CloudImageSourceService } from './cloud-image-source.service';
import { CloudRgbModule } from './cloud-rgb/cloud-rgb.module';
import { CloudTsModule } from './cloud-ts/cloud-ts.module';
import { CloudComponent } from './cloud.component';

@NgModule({
  declarations: [CloudComponent],
  imports: [
    CommonModule,
    RouterModule,
    TableModule,

    CloudRgbModule,
    CloudTsModule
  ],
  providers: [
    CloudImageSourceService
  ]
})
export class CloudModule { }
