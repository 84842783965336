<!-- <div class="is-boxed is-medium">
    <ul>
        <li routerLinkActive="is-active">
            <a [routerLink]="[ '/admin', 'members', 'list', 'permitted' ]">
                <span class="icon is-small"><i class="fas fa-user-check" aria-hidden="true"></i></span>
                <span>Permitted</span>
            </a>
        </li>
        <li routerLinkActive="is-active">
            <a [routerLink]="[ '/admin', 'members', 'list', 'wating' ]">
                <span class="icon is-small"><i class="fas fa-user-friends" aria-hidden="true"></i></span>
                <span>Wating</span>
            </a>
        </li>
    </ul>
</div> -->

<router-outlet></router-outlet>
