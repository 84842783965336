import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractTable } from '../abstract-table';

@Component({
  selector: 'app-image-table',
  templateUrl: './image-table.component.html',
  styleUrls: ['./image-table.component.css']
})
export class ImageTableComponent extends AbstractTable implements OnInit {

  ngOnInit(): void {
    this.makeImageUrl();
  }

  @Input()
  coulmns: number = 2;

  //is-12
  @Input()
  line: string = "fill";
  url: string = null;

  makeImageUrl(url?) {
    if(this.url != null) 
      this.url = url;
  }

}
