<div class="columns" >
    <div class="column" *ngFor="let d of data; let i = index">
        <div class="card">
            <header class="card-header">
                <p class="card-header-title">
                    {{ d?.title }}
                </p>
            </header>
            <div class="card-image">
                <figure class="image my-5 mx-5">
                    <img [src]="url"/>
                </figure>
            </div>
        </div>
    </div>
</div>


<!-- <div class="columns" *ngFor="let header of headers; let i = index">
    <div class="column">
        <div class="card">
            <header class="card-header">
                <p class="card-header-title" >
                    {{ header[0] }} 
                </p>
            </header>
            <div class="card-image">
                <figure class="image my-5 mx-5">
                    <img [src]="getImageUrl(data, i, 0)"/>
                </figure>
            </div>
        </div>
    </div>
    <div class="column" *ngIf="header[1] != null">
        <div class="card">
            <header class="card-header">
                <p class="card-header-title" >
                    {{ header[1] }}
                </p>
            </header>
            <div class="card-image">
                <figure class="image my-5 mx-5">
                    <img [src]="getImageUrl(data, i, 1)"/>
                </figure>
            </div>
        </div>
    </div>
</div> -->