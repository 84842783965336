import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressService } from './progress.service';
import { ProgressComponent } from './progress.component';

const components = [ProgressComponent];

@NgModule({
  declarations: components,
  imports: [
    CommonModule
  ],
  exports: components,
  providers: [
    ProgressService
  ]
})

export class ProgressModule { }
