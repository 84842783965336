import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractTable } from '../abstract-table';

@Component({
  selector: 'app-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.css']
})
export class BasicTableComponent extends AbstractTable implements OnInit {

  @Output()
  open = new EventEmitter<string>();

  ngOnInit(): void {
    if (this.open.observers.length <= 0)
      this.open = null;
  }

  onOpen(id: string) {
    this.open?.emit(id);
  }
}
