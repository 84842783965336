import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageColumnsComponent } from './image-columns.component';
import { DefaultImageModule } from '../default-image/default-image.module';

@NgModule({
  declarations: [
    ImageColumnsComponent
  ],
  imports: [
    CommonModule,
    DefaultImageModule
  ],
  exports: [
    ImageColumnsComponent
  ]
})
export class ImageColumnsModule { }
