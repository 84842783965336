import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComparisonComponent } from './comparison.component';
import { ContentComponent } from './content/content.component';
import { TimeRangeSliderModule } from 'src/app/common/calendar/time-range-slider/time-range-slider.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ValidationModule } from 'src/app/common/validation/validation.module';
import { ImageColumnsModule } from 'src/app/common/image-columns/image-columns.module';

@NgModule({
  declarations: [ComparisonComponent, ContentComponent],
  imports: [
    CommonModule,
    TimeRangeSliderModule,
    ReactiveFormsModule,
    ValidationModule,
    ImageColumnsModule
  ]
})
export class ComparisonModule { }
