import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultImageService } from '../default-image.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.css']
})
export class ImageModalComponent implements OnInit {

  @Input()
  opened: boolean = false;

  @Output()
  openedChange = new EventEmitter<boolean>();

  @Input()
  src: string = "";

  @Output()
  srcChange = new EventEmitter<string>();

  constructor(public service: DefaultImageService) { }

  ngOnInit(): void {
    this.service.src.subscribe(src => {
      this.opened = this.isValid(src);
      this.src = src;

      // console.log('hook!');
    });
  }

  isValid(src?: string) {
    return src != null && src.length > 0;
  }

  close() {
    this.opened = false;
    this.openedChange.emit(this.opened);

    this.src = "";
    this.srcChange.emit(this.src);
  }
}
