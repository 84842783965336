import { Component } from '@angular/core';

@Component({
  selector: 'app-cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.css']
})
export class CloudComponent {
  tabHeaders = [
    {
      name: 'GK2A AMI RGB + Aircraft path',
      value: 'rgb'
    },
    {
      name: 'Time Series of Reflectivity (VIS) and TB (IR)',
      value: 'ts'
    }
  ];
}
