import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HammerModule } from '@angular/platform-browser';
import { GalleryComponent } from './gallery.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxBulmaPaginationModule } from 'ngx-bulma-pagination';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'src/app/common/modal/modal.module';
import { ViewerComponent } from './viewer/viewer.component';
import { RichTextModule } from 'src/app/common/rich-text/rich-text.module';

@NgModule({
  declarations: [GalleryComponent, ViewerComponent],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    RichTextModule,
    NgxBulmaPaginationModule,
    HammerModule,
    ModalModule
  ]
})
export class GalleryModule { }
