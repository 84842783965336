import { NgModule } from '@angular/core';
import { FileSizePipe } from './file-size.pipe';
import { Titlecase2Pipe } from './titlecase2.pipe';

const pipes = [FileSizePipe, Titlecase2Pipe];

@NgModule({
  declarations: pipes,
  exports: pipes
})
export class CustomPipeModule { }
