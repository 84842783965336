import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateControlsModule } from 'src/app/common/calendar/date-controls/date-controls.module';
import { CloudTsComponent } from './cloud-ts.component';

@NgModule({
  declarations: [CloudTsComponent],
  imports: [
    CommonModule,
    DateControlsModule
  ]
})
export class CloudTsModule { }
