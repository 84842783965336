import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CloudModule } from './cloud/cloud.module';
import { GemsModule } from './gems/gems.module';
import { TropomiModule } from './tropomi/tropomi.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    GemsModule,
    TropomiModule,
    CloudModule
  ]
})
export class SatelliteModule { }
