import { Component, OnInit } from '@angular/core';
import { GoogleCalendarService } from 'src/app/http/google-calendar.service';

interface GoogleCalendarDateTime {
  dateTime: {
    value: number
    dateOnly: boolean
    timeZoneShift: number
  }
  timeZone: string
}

interface GoogleCalendarEvent {
  summary: string
  start: GoogleCalendarDateTime
  end: GoogleCalendarDateTime
}

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {

  sample = [
    {
      "created": {
        "value": 1633496728000,
        "dateOnly": false,
        "timeZoneShift": 0
      },
      "creator": {
        "email": "ryu@miraeclimate.com"
      },
      "end": {
        "dateTime": {
          "value": 1633500000000,
          "dateOnly": false,
          "timeZoneShift": 540
        },
        "timeZone": "Asia/Seoul"
      },
      "etag": "\"3266993457414000\"",
      "htmlLink": "https://www.google.com/calendar/event?eid=MHJyNnVlOGMzazhjMDEyNWlnNTY1amhqZ2kgY19zdHI0OHZwYmVldmVpc3Nwa3M5aTdjOXFta0Bn",
      "iCalUID": "0rr6ue8c3k8c0125ig565jhjgi@google.com",
      "id": "0rr6ue8c3k8c0125ig565jhjgi",
      "kind": "calendar#event",
      "organizer": {
        "displayName": "SIJAQ/GMAP 2021 Campaign",
        "email": "c_str48vpbeeveisspks9i7c9qmk@group.calendar.google.com",
        "self": true
      },
      "reminders": {
        "useDefault": true
      },
      "sequence": 0,
      "start": {
        "dateTime": {
          "value": 1633496400000,
          "dateOnly": false,
          "timeZoneShift": 540
        },
        "timeZone": "Asia/Seoul"
      },
      "status": "confirmed",
      "summary": "SIJAQ/GMAP 2021 Campaign 캘린더 만듬",
      "updated": {
        "value": 1633496728707,
        "dateOnly": false,
        "timeZoneShift": 0
      },
      "eventType": "default"
    }
  ]

  events: GoogleCalendarEvent[];

  constructor(private calendar: GoogleCalendarService) { }

  ngOnInit(): void {
    // this.calendar.events().subscribe(events => this.events = events);
  }
}
