import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-members-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent {

  headers = ["Name", "Charge", "Organization", "E-Mail", "Address", ""]

  expandedIndex = null;

  constructor(private route: ActivatedRoute, private router: Router) { }

  expand(index: number) {
    if (this.expandedIndex == null || this.expandedIndex != index) {
      this.expandedIndex = index;
    }
    else {
      this.expandedIndex = null;
    }
  }

  edit(event: MouseEvent, id: string) {
    event.stopPropagation();

    this.router.navigate(['edit'], {
      relativeTo: this.route,
      queryParams: { id: id }
    });
  }

  remove(event: MouseEvent, index: number) {
    event.stopPropagation();
  }

  keys(data) {
    return Object.keys(data);
  }
}
