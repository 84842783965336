import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnouncementsComponent } from './announcements.component';
import { RouterModule } from '@angular/router';
import { TableModule } from 'src/app/common/table/table.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ScheduleComponent } from './schedule/schedule.component';

@NgModule({
  declarations: [AnnouncementsComponent, ScheduleComponent],
  imports: [
    CommonModule,
    RouterModule,
    TableModule,
    ReactiveFormsModule
  ],
  exports: [AnnouncementsComponent]
})
export class AnnouncementsModule { }
