import { AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appTranslate]'
})
export class TranslateDirective implements OnInit, AfterViewInit, OnDestroy {

  private subscription: Subscription;
  private key: string;

  @Input()
  set appTranslate(key: string) {
    this.key = key;
  }

  @Input()
  appTranslateData: any;

  @Output()
  appTranslateDataChange = new EventEmitter<any>();

  constructor(private translate: TranslateService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscription = this.translate.get(this.key).subscribe(data => {
      this.appTranslateData = data;
      this.appTranslateDataChange.emit(this.appTranslateData);
    })
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}