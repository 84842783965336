import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryImageSize, NgxGalleryOptions } from "@kolkov/ngx-gallery";
import { FileType } from "./rich-text/file-type.enum";
import { File } from 'src/app/store/schema';

export class GallerySetting {
    static options: NgxGalleryOptions[] = [
      {
        width: '100%',
        thumbnails: false,
        imageSwipe: true,
        imageInfinityMove: true,
        preview: false,
        imageAnimation: NgxGalleryAnimation.Slide,
        imageBullets: true,
        imageArrows: false,
        imageAutoPlay: true,
        imageAutoPlayPauseOnHover: true,
        imageAutoPlayInterval: 3000,
        imageSize: NgxGalleryImageSize.Contain,
        imageDescription: true
      },
      {
        breakpoint: 800,
        width: '100%',
        imagePercent: 100,
      },
      {
        breakpoint: 500,
        preview: false
      }
    ];
  
    public static filesToImages(files: File[]) : NgxGalleryImage[] {
      const images = files?.filter(f => FileType.fileNameToFileType(f.name) == FileType.IMAGE);
  
      if (images == null || images.length <= 0)
        return;
  
      return images.map(image => ({ medium: image.url }));
    }
  }