<form [formGroup]="editorForm">
    <fieldset class="mb-2" *ngIf="controlGroup != null">   
        <div class="field is-grouped">
            <p class="control" *ngFor="let controls of controlGroup; let i=index">
                <app-select [controls]="controls" (indexChange)="changeSelect(i, $event)"></app-select>
            </p>
        </div>
    </fieldset>
    <fieldset class="mb-2">
        <div class="field has-addons">
            <p class="control is-expanded">
                <input class="input" type="text" formControlName="title" placeholder="Title" [value]="title">
            </p>
            <ng-content></ng-content>
        </div>
    </fieldset>

    <div class="summernote">
        <div [ngxSummernote]="config" formControlName="content" [summernoteModel]="content" required></div>
    </div>
</form>

<div *ngIf="hasAttachment">
    <div class="mt-4 mb-5">
        <div class="file has-name is-boxed is-fullwidth" (dragenter)="preventDrag($event)"
            (dragover)="preventDrag($event)" (dragleave)="isDrag = false" (drop)="dropFiles($event)">
            <label class="file-label">
                <input class="file-input" type="file" name="resume" [accept]="accept"
                    (change)="handleFiles(fileInput.files)" multiple #fileInput [(ngModel)]="filesName">
                <span class="file-cta has-text-centered">
                    <span class="file-icon">
                        <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label">
                        <div class="is-size-4 has-text-weight-semibold">{{ uploadActionMessage }}</div>
                        <div class="has-text-weight-semibold">{{ fileTypeMessage }}</div>
                        <div class="mt-3">{{ uploadLimitMessage }}</div>
                    </span>
                </span>
            </label>
        </div>

        <div class="mt-4" *ngIf="this.fileList?.length > 0">
            <div class="columns is-mobile is-gapless">
                <div class="column">
                    {{ totalFileSize | fileSize }}
                </div>
                <div class="column">
                    <div class="buttons is-right">
                        <button class="button is-small" (click)="clear()">
                            <i class="far fa-trash-alt mr-1"></i>
                            <span>Clear</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="columns is-mobile is-vcentered" *ngFor="let file of fileList; let i=index">
                <div class="column">
                    <ng-template [ngIf]="isImage(file)">
                        <img class="thumbnail" [attr.data-index]="i" #imgs>
                    </ng-template>
                    {{ file.name }}
                </div>
                <div class="column is-2 has-text-right">
                    {{ file.size | fileSize }}
                </div>
                <div class="column is-1 has-text-right">
                    <button class="delete mr-1" (click)="deleteFile(i)"></button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="menu mt-5 mb-3" *ngIf="uploadedFiles">
    <p class="menu-label">
        <span>Uploaded files</span>
        <span> ({{ totalUploadedFileSize | fileSize }})</span>
    </p>
    <ul class="menu-list">
        <li *ngFor="let file of uploadedFiles ; let i = index">
            <a>
                <div class="columns is-mobile is-vcentered">
                    <div class="column">
                        <i class="far" [ngClass]="extensionToIconClass(file.name)"></i>
                        <span class=" ml-1">{{ file.name }}</span>
                    </div>
                    <div class="column is-2 has-text-right">
                        {{ file.size | fileSize }}
                    </div>
                    <div class="column is-1 has-text-right">
                        <button class="delete mr-1" (click)="deleteUploadedFile(i)"></button>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>

<div class="buttons is-right mt-3">
    <button class="button" (click)="navigateToBack()">
        Cancel
    </button>
    <button type="submit" class="button" [ngClass]="saveButtonColorClass" [disabled]="!editorForm.valid"
        (click)="savePost()">
        Save
    </button>
</div>