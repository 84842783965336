import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultImageDirective } from './default-image.directive';
import { DefaultImageService } from './default-image.service';
import { ImageModalComponent } from './image-modal/image-modal.component';

@NgModule({
  declarations: [DefaultImageDirective, ImageModalComponent],
  imports: [
    CommonModule
  ],
  exports: [DefaultImageDirective, ImageModalComponent],
  providers: [
    DefaultImageService
  ]
})
export class DefaultImageModule { }
