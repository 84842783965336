import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherMapComponent } from './weather-map.component';
import { TableModule } from 'src/app/common/table/table.module';
import { ImageColumnsModule } from 'src/app/common/image-columns/image-columns.module';



@NgModule({
  declarations: [WeatherMapComponent],
  imports: [
    CommonModule,
    TableModule,
    ImageColumnsModule
  ],
  exports: [ 
    WeatherMapComponent
  ]
})
export class WeatherMapModule { }
