import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AbstractModal } from 'src/app/common/modal/abstract-modal';

@Component({
  selector: 'app-private-policy-modal',
  templateUrl: './private-policy-modal.component.html',
  styleUrls: ['./private-policy-modal.component.css']
})
export class PrivatePolicyModalComponent extends AbstractModal {
}
