<p class="subtitle has-text-weight-bold is-size-3">[Forecast-East Asia] Weather Chart (SFC)</p>
<!-- <app-image-table [headers]="headers" [data]="getImageSources('surfce')" ></app-image-table> -->
<app-image-columns [data]="getImageSources('surfce')" [column]="2"></app-image-columns>

<p class="subtitle has-text-weight-bold is-size-3">[Forecast-East Asia] Weather Chart (850hPa)</p>
<!-- <app-image-table [headers]="headers" [data]="getImageSources('gph850')" ></app-image-table> -->
<app-image-columns [data]="getImageSources('gph850')" [column]="2"></app-image-columns>

<p class="subtitle has-text-weight-bold is-size-3">[Forecast-East Asia] T-Td (700hPa)</p>
<!-- <app-image-table [headers]="headers" [data]="getImageSources('ttd700')" ></app-image-table> -->
<app-image-columns [data]="getImageSources('ttd700')" [column]="2"></app-image-columns>

<p class="subtitle has-text-weight-bold is-size-3">[Forecast-East Asia] Weather Chart (500hPa)</p>
<!-- <app-image-table [headers]="headers" [data]="getImageSources('gph500')" ></app-image-table> -->
<app-image-columns [data]="getImageSources('gph500')" [column]="2"></app-image-columns>


