import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CopyrightModule } from 'src/app/common/copyright/copyright.module';
import { DefaultImageModule } from 'src/app/common/default-image/default-image.module';
import { AmbientAirConcentrationsComponentModule } from './ambient-air-concentrations/ambient-air-concentrations.module';
import { ModelingModule } from './modeling/modeling.module';
import { RoutineDataCollectedComponent } from './routine-data-collected.component';
import { GroundBasedRemoteSensingdataModule } from './ground-based-remote-sensingdata/ground-based-remote-sensingdata.module';
import { SatelliteModule } from './satellite/satellite.module';
import { UnderConstructionModule } from './under-construction/under-construction.module';
import { WeatherAnalysisModule } from './weather-analysis/weather-analysis.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AmbientAirConcentrationsComponentModule,
    SatelliteModule,
    GroundBasedRemoteSensingdataModule,
    ModelingModule,
    CopyrightModule,
    UnderConstructionModule,
    DefaultImageModule,
    WeatherAnalysisModule
  ],  
  providers: [
  ]
  , declarations: [RoutineDataCollectedComponent]
})
export class RoutineDataCollectedModule { }
