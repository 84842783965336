import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SatelliteComponent } from './satellite.component';

@NgModule({
  declarations: [
    SatelliteComponent
  ],
  imports: [
    CommonModule    
  ],
  exports: [
    SatelliteComponent
  ]
})
export class SatelliteModule { }
