import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EastAsiaComponent } from './east-asia.component';
import { ImageColumnsModule } from 'src/app/common/image-columns/image-columns.module';



@NgModule({
  declarations: [
    EastAsiaComponent
  ],
  imports: [
    CommonModule,
    ImageColumnsModule
  ],
  exports: [
    EastAsiaComponent
  ]
})
export class EastAsiaModule { }
