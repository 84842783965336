<app-private-policy-modal [(active)]="isShowPrivacyPolicyModal"></app-private-policy-modal>

<section class="section">
    <div class="columns">
        <div
            class="column is-12-mobile is-6-tablet is-5-desktop is-4-fullhd is-offset-3-tablet is-offset-4-desktop is-offset-4-fullhd">
            <form class="card" [formGroup]="form">
                <section class="hero">
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title">
                                Sign up
                            </h1>
                        </div>
                    </div>
                </section>

                <div class="card-content">
                    <ng-container *ngIf="!isSumitted; else elseTemplate">
                        <app-input-field #email [(form)]="form" [controlName]="'email'" [iconClass]="'fa-envelope'" [type]="'email'" [label]="'E-Mail'" [placeholder]="'E-Mail'" [invalidMessage]="emailMessage(email)"></app-input-field>

                        <!-- <app-radio-field [values]="nationality.values" [images]="nationality.images" [(form)]="form" [controlName]="'nationality'"></app-radio-field> -->

                        <app-input-field [(form)]="form" [controlName]="'name'" [iconClass]="'fa-id-badge'"></app-input-field>

                        <app-input-field [(form)]="form" [controlName]="'password'" [iconClass]="'fa-key'" [type]="'password'" [invalidMessage]="invalidPasswordMessage"></app-input-field>

                        <app-input-field [(form)]="form" [controlName]="'confirmPassword'" [iconClass]="'fa-key'" [type]="'password'" [label]="'Confirm password'" [placeholder]="'Confirm password'"></app-input-field>

                        <div class="field field-container">
                            <div class="control">
                                <label class="checkbox">
                                    <input type="checkbox" formControlName="agreeClause">
                                </label>
                                I agree with the <a (click)="isShowPrivacyPolicyModal = true">privacy policy</a>.
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <div class="buttons is-right">
                                    <button type="submit" class="button is-fullwidth is-rounded is-outlined is-primary"
                                        (click)="submit()" [disabled]="!form.valid">Submit</button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div>
                            <span class="is-size-5 ">
                                <i class="fas fa-hand-paper mr-2"></i>
                                Please wait until the manager approves your signup request.
                            </span>
                        </div>
                    </ng-template>

                </div>
            </form>
        </div>
    </div>
</section>