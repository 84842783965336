import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateControlsModule } from 'src/app/common/calendar/date-controls/date-controls.module';
import { DefaultImageModule } from 'src/app/common/default-image/default-image.module';
import { CloudRgbComponent } from './cloud-rgb.component';

@NgModule({
  declarations: [CloudRgbComponent],
  imports: [
    CommonModule,
    DateControlsModule,
    DefaultImageModule
  ]
})
export class CloudRgbModule { }
