import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateDirective } from './translate.directive';

const directives = [TranslateDirective];

@NgModule({
  declarations: directives,
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: directives
})
export class TranslateDirectiveModule { }
