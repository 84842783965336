import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DateControlsModule } from 'src/app/common/calendar/date-controls/date-controls.module';
import { DefaultImageModule } from 'src/app/common/default-image/default-image.module';
import { TableModule } from 'src/app/common/table/table.module';
import { TropomiComponent } from './tropomi.component';

@NgModule({
  declarations: [TropomiComponent],
  imports: [
    CommonModule,
    RouterModule,
    TableModule,
    DefaultImageModule,
    DateControlsModule
  ]
})
export class TropomiModule { }
