import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import * as dateFns from "date-fns";

@Component({
  selector: 'app-cloud-ts',
  templateUrl: './cloud-ts.component.html',
  styleUrls: ['./cloud-ts.component.css']
})
export class CloudTsComponent implements AfterViewInit {

  currentDate = new Date();
  private newRuleDate = new Date(2021, 10, 7);

  sources?: string[];
  private errors?: boolean[];

  // alterImage = 'assets/images/error/1x1.png';
  alterImage = "assets/images/error/image_unavailable.gif";

  constructor(private changeRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  onDateChanged(date: Date) {
    this.currentDate = dateFns.startOfDay(date);

    const basicFileName = `GK2A_AMI_L1BTimeSeriesGCAS_${dateFns.format(this.currentDate, 'yyyyMMdd')}`;
    const dirs = ['image', dateFns.format(this.currentDate, "yyyy/MM/dd"), 'satellite', 'cloud', 'ts'].join('/');

    if (dateFns.isBefore(this.currentDate, this.newRuleDate)) {
      this.sources = [`${dirs}/${basicFileName}.png`];
    }
    else {
      this.sources = [...Array(9).keys()].map(i => `${dirs}/${basicFileName}_${i + 1}.png`);
    }

    this.errors = [...Array(this.sources.length).keys()].map(_ => false);
  }

  onOccuredError(index: number) {
    this.errors[index] = true;
  }

  hasError(index: number) {
    if (this.errors == null)
      return false;

    return this.errors[index];
  }

  isAllNotAvailable() {
    if (this.errors == null)
      return true;

    return this.errors.every(b => b);
  }
}
