import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/http/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  currentLang: string;
  langs = ['ko', 'zh-cht', 'jp'];
  countries = ['korea', 'china', 'japan'];

  isExpanded = false;

  constructor(public auth: AuthService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(({ lang }) => {
      this.currentLang = lang;
    });
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  // Toggle 이외의 누르는 동작은 메뉴 닫기
  @HostListener('click', ['$event.target'])
  onClick(target) {
    if (target.classList.contains('navbar-burger'))
      return;

    this.isExpanded = false;
  }

  changeLang(lang: string) {
    this.translate.use(lang);
  }

  signout(){
    sessionStorage.removeItem("token");
    window.location.href = "/";
  }
}
