import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { RouterModule } from '@angular/router';
import { ValidationModule } from 'src/app/common/validation/validation.module';
import { ModalModule } from 'src/app/common/modal/modal.module';
import { PrivatePolicyModalComponent } from './sign-up/private-policy-modal/private-policy-modal.component';
import { ProfileComponent } from './profile/profile.component';

@NgModule({
  declarations: [SignInComponent, SignUpComponent, PrivatePolicyModalComponent, ProfileComponent],
  imports: [
    CommonModule,
    RouterModule,
    ValidationModule,
    ModalModule
  ]
})
export class AuthModule { }
