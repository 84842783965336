import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gcas-measurements',
  templateUrl: './gcas.component.html'
})
export class GcasMeasurementsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
