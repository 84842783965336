import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractModal } from 'src/app/common/modal/abstract-modal';

@Component({
  selector: 'app-check-remove-post-modal',
  templateUrl: './check-remove-post-modal.component.html',
  styleUrls: ['./check-remove-post-modal.component.css']
})
export class CheckRemovePostModalComponent extends AbstractModal {
  @Output()
  remove = new EventEmitter<any>();

  onRemove() {
    this.remove.emit();
  }
}
