import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ValidatorService } from 'src/app/common/validation/validation.service';
import { AuthService } from 'src/app/http/auth.service';
import { userType, role, nationality, enumToArray } from 'src/app/main/section/auth/userinfo.enum';

@Component({
  selector: 'app-members-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  email: string;
  form: FormGroup;
  private expw;

  invalidPasswordMessage = "Must be at least 8 characters, including letters (case sensitive), numbers, and at least 1 special character.";

  nationality = {
    values: enumToArray(nationality),
    images: ["assets/images/flag/korea-flag-small.png", "assets/images/flag/china-flag-small.png", "assets/images/flag/japan-flag-small.png"]
  };

  usertype = {
    values: enumToArray(userType),
    subLabels: []
  };

  roles = enumToArray(role);

  constructor(public auth: AuthService, private fb: FormBuilder, public validation: ValidatorService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.email = this.route.snapshot.queryParams?.email;   
    this.formgroupInit(null);    
    this.auth.user(this.email).subscribe(userinfo => {
      this.expw = userinfo['password'];
      this.formgroupInit(userinfo);
    });
  }

  formgroupInit(userinfo) {
    const nflag = userinfo == null;

    this.form = this.fb.group({
      id: [nflag ? '' : userinfo['id']],
      password: ['', [Validators.required, this.validation.passwordValidator()]],
      email: [nflag ? '' : userinfo['email'], [Validators.required]],
      nationality: [nflag ? '' : userinfo['nationality'], [Validators.required]],
      name: [nflag ? '' : userinfo['name'], [Validators.required]],
      charge: [nflag ? '' : userinfo['charge'], [Validators.required]],
      organization: [nflag ? '' : userinfo['organization'], [Validators.required]],
      telephone: [nflag ? '' : userinfo['telephone'], [Validators.required]],
      address: [nflag ? '' : userinfo['address'], [Validators.required]],
      usertype: [nflag ? '' : userinfo['usertype'], [Validators.required]],
      role: [nflag ? '' : userinfo['role'].toLowerCase(), [Validators.required]],
      permit: [nflag ? '' : userinfo['permit']]
    });
  }

  onSubmit() {
    const value = this.form.value;
    if(value['password'] == '') {
      value['password'] = this.expw;
    }
     
    this.auth.update(value).subscribe(_response =>{
      alert("successfully updated");

      }, (error: HttpErrorResponse) =>{
        console.log(error);
      }
    );
  }

  ngOnDestroy(): void {
  }

}
