import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentModule } from './current/current.module';
import { ForecastModule } from './forecast/forecast.module';



@NgModule({
  declarations: [    
  ],
  imports: [
    CommonModule,
    CurrentModule,
    ForecastModule
  ]
})
export class WeatherAnalysisModule { }
