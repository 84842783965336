import { EventEmitter, Injectable } from '@angular/core';

export interface CloudImageSource {
  activatedUrl: string;
  sources: string[];
}

@Injectable()
export class CloudImageSourceService {
  readonly imageSourceChange = new EventEmitter<CloudImageSource>();
}
