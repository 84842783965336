import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { PostService } from './post.service';
import { PresentationFilesService } from './presentation-files.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  data: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private postService: PostService
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const isLoggedIn = this.authService.jwt != null;
    const isStaff = this.authService.role == "ROLE_STAFF";
    const isEditor = route.queryParams["editor"] == this.authService.who();
    const path = route.url[0].path;

    //로그인 페이지 or 가입페이지
    if (path === "signin") {
      //이미 로그인이 되어있을 시 메인으로
      if (isLoggedIn) {
        this.router.navigateByUrl("/");
      }
      return !isLoggedIn;
      //어드민 페이지
    } else if (path === "signup" || route.url[0].path === "members") {
      //로그인 체크
      if (isLoggedIn) {        
        //스태프 체크
        if(isStaff) return true;
        else this.router.navigateByUrl("/");

        //인증 없을 시 메인으로
      } else {
        this.router.navigateByUrl("/");
      }
      //edit페이지인데 글쓴이가 아니거나 관리자가 아닐때 접근못하게 
    } else if (path === "edit") {
      if (isStaff || isEditor) return true;
      else this.router.navigateByUrl("/");
    
    //other campaign path로 접근시 로그인후 해당 path로
    } else if(path == "aircraft-others"){
      if(!isLoggedIn)
        this.router.navigate(["/signin"], {queryParams: {prop: state.url}})
      else return isLoggedIn;  
    //그 외의 다른 페이지인데 인증이 필요할시    
    }else {
      //console.log(isLoggedIn);
      if (isLoggedIn) return isLoggedIn;
      else this.router.navigateByUrl("/signin");
    }
  }
}
