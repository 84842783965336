import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import * as dateFns from 'date-fns';

@Component({
  selector: 'app-east-asia',
  templateUrl: './east-asia.component.html',
  styleUrls: ['./east-asia.component.css']
})
export class EastAsiaComponent  {

  rootUrl = "https://www.weather.go.kr/w/repositary/image/cht/img/";

  headers = ["day +1", "day +2", "day +3", "day +4", "day +5", "day +6"]

  imageArray: any;

  fillZero(num){    
    return num.toString().length < 3 ? '0' + num : num;
  }

  getImageSources(filename){
    this.imageArray = {};

    const fileNames = ["surfce", "gph850", "ttd700", "gph500"];

    fileNames.map((filename)=> {
      let tempArray = [];
      this.headers.map((_h, i) => {

        tempArray.push({
          title: this.headers[i],
          src: [this.rootUrl+"kim_gdps_erly_asia_"+filename+"_ft06_pa4","s"+this.fillZero((i+1)*24),dateFns.format(new Date(), "yyyyMMdd")+"00.png"].join("_")
        });
      });
      this.imageArray[filename] = tempArray;
    });    

    return this.imageArray[filename];
  }

}

