import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectService {
  typeToControls(typeArr: string[][], isRequiredAll: boolean = true) {
    if (typeArr == null)
      return null;

    return typeArr.reduce((result: any[], selectArr: string[]) => {
      let temp = [];

      // if (isRequiredAll) {
      //   temp.push(
      //     {
      //       name: '전체',
      //       value: 0,
      //       checked: true
      //     }
      //   );
      // }      
  
      temp = temp.concat(selectArr.map((t, i) => ({
        name: t,
        value: i,
        checked: false
      })));
      
      result.push(temp);

      return result;
    }, []);
  }

  encode(codes: number[]): number {
    return codes?.reverse().reduce((p, r, i) => p + (r * Math.pow(10, i)), 0);
  }

  decode(code: number): number[] {
    if(code == null) return;

    const result = [];

    let temp = code;
    let length = code.toString().length;

    do {
      const adjustor = Math.pow(10, length - 1);
      result.push(Math.floor(temp / adjustor));
      
      temp = code % adjustor;

      length--;
    } while (length > 0);

    return result;
  }
}
