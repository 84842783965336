<app-modal [(active)]="active">
    <div content>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque imperdiet enim est, quis ullamcorper magna
        gravida et. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
        Suspendisse id pulvinar est. Quisque et malesuada turpis. Integer luctus consectetur risus at ornare. In
        faucibus nulla fermentum blandit posuere. Integer et sodales nulla, et tincidunt metus. Integer suscipit purus
        ut magna rutrum venenatis. Nunc at nibh eget diam fringilla mollis et at metus. Aliquam volutpat felis
        venenatis, convallis quam eget, egestas nulla. Vestibulum dapibus eleifend est, nec maximus urna molestie ac.
        Aliquam non enim et libero accumsan sollicitudin. Maecenas sit amet augue lectus.

        Sed pellentesque turpis sed leo imperdiet, in scelerisque mi scelerisque. Fusce rutrum odio a lobortis rhoncus.
        Duis placerat elementum tortor. Nam a turpis tortor. Nunc rhoncus sodales nibh sed lacinia. Pellentesque
        habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Pellentesque lobortis iaculis
        ante, sit amet gravida dui iaculis ac. Nulla eu purus eu ex blandit feugiat sed nec mauris. Nullam hendrerit
        eros eget urna aliquam vulputate. Aenean lacinia justo ac ligula molestie, eget mattis lorem volutpat. Aliquam
        erat volutpat. Fusce cursus leo tincidunt ligula condimentum, ut ullamcorper libero elementum. Vestibulum
        euismod nulla eget justo laoreet, ac bibendum leo varius. Morbi at dui quis justo accumsan dictum eu eget ante.
        Cras a est vel sem mollis euismod nec quis elit.

        Aenean volutpat vestibulum ante, at maximus lorem vulputate sit amet. Pellentesque habitant morbi tristique
        senectus et netus et malesuada fames ac turpis egestas. Vestibulum suscipit id enim ut ultrices. Nunc
        pellentesque vehicula commodo. Suspendisse rhoncus euismod ex id mollis. Fusce tempus tincidunt augue sit amet
        suscipit. Aenean a mattis massa, sed dapibus mauris. Cras tincidunt ante et libero pretium ullamcorper.

        Nam semper eget velit eget finibus. Sed euismod blandit nunc, ut malesuada est rutrum ut. Donec eros turpis,
        ornare lobortis purus sed, eleifend vehicula est. Nunc tempus justo non magna maximus, eget laoreet odio porta.
        Donec rhoncus ipsum in sapien congue vulputate. Nulla facilisi. In tellus lorem, sagittis at maximus vel,
        euismod eu dolor. Maecenas vel nibh lacinia, tincidunt turpis ac, dictum sapien.

        Duis ullamcorper velit non est pretium porttitor. Sed consectetur orci massa, sit amet eleifend purus blandit a.
        Integer scelerisque, sem sit amet iaculis consectetur, diam ipsum mattis felis, eu mattis mauris enim in tellus.
        Curabitur urna magna, aliquam in convallis vehicula, ultricies sed libero. Mauris lacus orci, lacinia vitae
        finibus sed, posuere a dui. Phasellus tincidunt laoreet mi, vel mollis ante fringilla a. In commodo ligula diam,
        eu ultrices magna dictum vel. Duis non eleifend magna. Integer augue libero, convallis eget enim in, accumsan
        pharetra felis. Suspendisse sed libero ac tortor tincidunt cursus ac nec lectus. Aliquam metus ligula, laoreet
        vel efficitur consectetur, facilisis et neque. Integer vel euismod tellus, non mattis est.

        Generated 5 paragraphs, 463 words, 3127 bytes of Lorem Ipsum
    </div>
    <div buttons>
        <div class="buttons is-right">
            <button class="button is-white">Close</button>
        </div>
    </div>
</app-modal>