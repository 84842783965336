import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as dateFns from 'date-fns';

@Component({
  selector: 'app-tropomi',
  templateUrl: './tropomi.component.html',
  styleUrls: ['./tropomi.component.css']
})
export class TropomiComponent {

  currentDate = new Date();

  tabNames = [ 'NO2', 'O3T', 'HCHO' ];
  imageHeaders: { name: string, value: string }[] = [
    { name: 'EAST_ASIA', value: 'EA' },
    { name: 'GEMS_FULL', value: 'FULL'},
    { name: 'KOREA', value: 'LA' }
  ];

  private activatedPath;
  private nrtImageSources = [];
  private offlImageSources = [];

  constructor(private route: ActivatedRoute) {

    console.log("hoook!");

    this.activatedPath = this.route.snapshot.url[0].path;
    this.nrtImageSources = this.generateImageSources('NRT');
    this.offlImageSources = this.generateImageSources('OFFL');
  }

  onDateChanged(date: Date) {
    this.currentDate = date;
    this.nrtImageSources = this.generateImageSources('NRT');
    this.offlImageSources = this.generateImageSources('OFFL');
  }

  private generateImageSources(type: 'NRT' | 'OFFL'): string[] {
    const product = this.tabNames.find(t => t.toLowerCase() == this.activatedPath);

    const formattedDate = dateFns.format(this.currentDate, "yyyyMMdd");

    return this.imageHeaders.map(header => {
      const dirs = ['image', dateFns.format(this.currentDate, "yyyy/MM/dd"), 'satellite', 'tropomi', product.toLowerCase()].join('/');
      const fileName = ['S5P', type, product, formattedDate, header.value].join('_') + '.png';

      return dirs + '/' + fileName;
    });
  }
}
