import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimeRangeSliderModule } from 'src/app/common/calendar/time-range-slider/time-range-slider.module';
import { ImageColumnsModule } from 'src/app/common/image-columns/image-columns.module';
import { AmbientAirConcentrationsComponent } from './ambient-air-concentrations.component';

@NgModule({
  declarations: [AmbientAirConcentrationsComponent],
  imports: [
    CommonModule,
    TimeRangeSliderModule,
    ImageColumnsModule
  ]
})
export class AmbientAirConcentrationsComponentModule { }
