import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CurrentComponent } from './current.component';
import { RaderModule } from './rader/rader.module';
import { SatAndRadarModule } from './sat-and-radar/sat-and-radar.module';
import { SatelliteModule } from './satellite/satellite.module';
import { WeatherMapModule } from './weather-map/weather-map.module';


@NgModule({
  declarations: [
    CurrentComponent
  ],
  imports: [
    CommonModule,
    WeatherMapModule,
    SatelliteModule,
    RaderModule,
    SatAndRadarModule,
    RouterModule
  ],
  exports: [CurrentComponent]
})
export class CurrentModule { }
