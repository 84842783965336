import { Component, OnChanges } from '@angular/core';
import { AbstractModal } from "./abstract-modal";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent extends AbstractModal implements OnChanges {

  ngOnChanges({ active }: any): void {
    document.getElementsByTagName("html")[0].style.overflow = active.currentValue ? "hidden" : "auto";
  }
}
