import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive()
export abstract class AbstractModal {
  @Input()
  active: boolean = false;

  // two-way binding. ~change
  @Output()
  activeChange = new EventEmitter<boolean>();

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    const isBackground = target.classList[0] == 'modal-background';
    const isButton = target.tagName.toLowerCase() == 'button'
    if (isBackground || isButton)
      this.activeChange.emit(false);
  }
}
