import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PredictionComponent } from './prediction.component';
import { TableModule } from 'src/app/common/table/table.module';
import { EastAsiaModule } from './east-asia/east-asia.module';
import { SouthKoreaModule } from './south-korea/south-korea.module';



@NgModule({
  declarations: [
    PredictionComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    EastAsiaModule,
    SouthKoreaModule
  ],
  exports: [
    PredictionComponent
  ]
})
export class PredictionModule { }
