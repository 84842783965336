import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidatorService } from 'src/app/common/validation/validation.service';
import { AuthService } from 'src/app/http/auth.service';
import { tap, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  form: FormGroup;
  isShowPrivacyPolicyModal = false;
  isSumitted = false;
  isAvaliable: false;

  invalidPasswordMessage = "Must be at least 8 characters, including letters (case sensitive), numbers, and at least 1 special character.";
  emailAvaliableMessage = "";
  
  nationality = {
    values: ['korea', 'china', 'japan'],
    images: ["assets/images/flag/korea-flag-small.png", "assets/images/flag/china-flag-small.png", "assets/images/flag/japan-flag-small.png"]
  };

  constructor(private fb: FormBuilder, private auth: AuthService, public validation: ValidatorService, private router: Router) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, this.validation.emailValidator()]],
      name: ['', [Validators.required]],
      password: ['', [Validators.required, this.validation.passwordValidator()]],
      confirmPassword: ['', [Validators.required, this.validation.passwordValidator()]],
      agreeClause: [false, Validators.requiredTrue]
    }, {
      validators: [this.validation.match('password', 'confirmPassword'), this.validation.emailAvaliable('email')]
    });    
  }

  emailMessage(email){
    let error = email.form.controls['email']['errors']; 

    if(error != null && error.notUnique) this.emailAvaliableMessage = "This email address is already in use";
    else if(error != null && error.pattern) this.emailAvaliableMessage = "This email address is not avaliable";
    else this.emailAvaliableMessage = "";

    return this.emailAvaliableMessage;   
  }

  closePrivacyPolicyModal(_e) {
    this.isShowPrivacyPolicyModal = false;
  }

  submit() {
    const value = this.form.value;
    delete value['confirmPassword'];
    delete value['agreeClause'];
    value['role'] = 'MEMBER';

    this.auth.join(value).subscribe(
      res => {},
      (error:HttpErrorResponse) => { console.log(error); }
    );

    this.isSumitted = true;
  }
}
