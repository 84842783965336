import { Directive, HostListener, Input } from '@angular/core';
import { DefaultImageService } from './default-image.service';

@Directive({
  selector: 'img[src]',
  host: {
    '[src]': 'src',
    '(error)': 'adjustUrl()'
  }
})
export class DefaultImageDirective {
  private _src?: string;
  
  @Input()
  default = "assets/images/error/image_unavailable.gif";

  @Input()
  set src(src: string | undefined) {
    this._src = src;
  }

  constructor(private service: DefaultImageService) { }

  get src() {
    return (this._src != null && this._src.length > 0) ? this._src : this.default;
  }

  adjustUrl() {
    this.src = this.default;
  }

  @HostListener("click", ['$event.target'])
  clickedImage(_target: any) {
    if (this.src == this.default)
      return;

    this.service.src.emit(this.src);
  }
}
