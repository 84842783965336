import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourcesTableModule } from 'src/app/common/table/resources-table/resources-table.module';
import { TableModule } from 'src/app/common/table/table.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SelectModule } from 'src/app/common/select/select.module';
import { OthersComponent } from './others.component';
import { AircraftOthersComponent } from './aircraft-others/aircraft-others.component';



@NgModule({
  imports: [
    CommonModule, ResourcesTableModule, TableModule, ReactiveFormsModule, RouterModule, SelectModule

  ],
  declarations: [OthersComponent, AircraftOthersComponent]
})
export class OthersModule { }
