import { EventEmitter, Injectable } from '@angular/core';

interface Schema {
  relativeMinHour?: number,
  relativeMaxHour?: number
}

@Injectable()
export class TimeRangeSliderService {
  public readonly resetIndices = new EventEmitter<void>();
}
